$baseUrl: unquote("devURL");
@charset 'UTF-8';

@import './style.scss';

/* --- Blocks --- */
@import './project-modules/footer';
@import './project-modules/navbar';

.w-xxl-50 {
  @media (min-width: 1400px) {
    width: 50% !important; 
  }
}